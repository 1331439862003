import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { MenuItemLink, setSidebarVisibility } from "react-admin";
import Box from "@material-ui/core/Box";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";

import whatsNextStyles from "../../widgets/whatsNextCard/Style.module.css";
import useStyles from "./Menu.styles";

import { ReactComponent as ManageWebpageIcon } from "assets/vectors/sidebar/webpage.svg";
import { ReactComponent as StarIcon } from "assets/vectors/sidebar/star.svg";
import { ReactComponent as ExlyPlusIcon } from "assets/vectors/sidebar/exly_plus.svg";
import { ReactComponent as CustomersIcon } from "assets/vectors/sidebar/customers.svg";
import { ReactComponent as CopyIcon } from "assets/vectors/filled_copy.svg";
import { ReactComponent as DashboardIcon } from "assets/vectors/sidebar/dashboard.svg";
import { ReactComponent as ListingsIcon } from "assets/vectors/sidebar/listings.svg";
import { ReactComponent as SalesIcon } from "assets/vectors/sidebar/sales.svg";
import { ReactComponent as TransactionIcon } from "assets/vectors/sidebar/transactions.svg";
import { ReactComponent as DoubleArrowIcon } from "assets/vectors/sidebar/doubleArrow.svg";
import { ReactComponent as IntegrationsIcon } from "assets/vectors/sidebar/integrations.svg";
import { ReactComponent as TalkToExpertIcon } from "assets/vectors/sidebar/talk_to_expert.svg";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

import { is_empty } from "../../../utils/validations";
import SubMenu from "./components/SubMenu/SubMenu";
import ParentMenu from "./components/ParentMenu/ParentMenu";
import dataProvider from "../../../data/dataProvider";
import api from "../../../data/APIs";
import {
  getTasksCompletedPercentage,
  getWhatsNextInfo,
} from "../../../utils/whatsNext";
import { orgPermissions, RESOURCE_KEYS } from "../../../utils/OrgPermissions";
import {
  getPermissions,
  getUserChecks,
  setNextStepsInLocalStorage,
} from "../../../utils/AuthUtil";
import {
  getOnboardingContent,
  isRequestSuccessful,
} from "../../../utils/Utils";
import { getCreatorHostsiteURL } from "features/Account/utils/getCreatorPageDetails";
import CustomMenuItemLink from "./components/CustomMenuItemLink";
import { useNotifications, useToggleState } from "utils/hooks";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import {
  app_pathnames,
  app_route_ids,
  app_route_keys,
  app_route_titles,
} from "constants/urlPaths";
import { isDeviceIOS } from "common/util";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { comms } from "ui/pages/schedule/MangageCommunication/mangageCommunication.constants";
import { openExlySupportWhatsAppUrl } from "utils/exlySupport";
import { EXLY_CONNECT_TABS } from "features/ExlyConnect/ExlyConnect.constants";
import { apiMethods } from "data/api.constants";
import { WHATS_NEXT_APIS } from "features/Onboarding/modules/WhatsNext/WhatsNext.api";
import {
  LISTINGS_ROUTE_IDS,
  LISTINGS_ROUTE_KEYS,
} from "features/Listings/constants/Listings.urlPaths";
import { SESSION_STORAGE_KEYS } from "features/Common/modules/Storage/modules/SessionStorage/constants/SessionStorage.constant";

const initialState = {
  menuManageWebPage: false,
  menuManageSchedule: false,
  menuTransactions: false,
  menuSchedule: false,
  menuMail: false,
  paymentLinks: false,
  myLeads: false,
  marketing: false,
  sales: false,
  menuCustomers: false,
  menuExlyPlus: false,
  menuWorkflowTools: false,
};

const whatsNextUrl = `${window.location.origin}/#/whats-next`;

const getUniqueMenuItemLinkProps = ({ routeKey, pathNameFunctionParams }) => {
  const pathNameFunction = app_pathnames[routeKey] || (() => {});
  return {
    to: pathNameFunction(pathNameFunctionParams),
    id: app_route_ids[routeKey],
    primaryText: app_route_titles[routeKey],
  };
};

const Menu = ({ onMenuClick = () => {}, dense = false }) => {
  const [state, setState] = useState(initialState);

  const [isLoading, startLoading, stopLoading] = useToggleState(false);
  const [premium_data, set_premium_data] = React.useState(null);
  const [liveFeatures, setLiveFeatures] = React.useState({});
  const [taskCompletedPercentage, setTaskCompletedPercentage] =
    React.useState(0);
  const [permissions, set_permissions] = React.useState(null);
  const is_desktop = useDesktopMediaQuery();
  const { notify } = useNotifications();
  const open = useSelector((state) => state.admin.ui.sidebarOpen);

  const dispatch = useDispatch();
  useSelector((state) => state.theme); // force rerender on theme change
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const isDesktop = useDesktopMediaQuery();

  const isMainUser = orgPermissions.isMainUser();
  const canAccessOrg = isMainUser || orgPermissions.isOwner();
  const hiddenOfferings =
    permissions?.[RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS] &&
    is_empty(permissions[RESOURCE_KEYS.SECTIONS.CREATE_EDIT_LISTINGS]);

  const hasExlyConnectAccessPermission = !(
    permissions?.[RESOURCE_KEYS.SECTIONS.EXLY_CONNECT] &&
    is_empty(permissions[RESOURCE_KEYS.SECTIONS.EXLY_CONNECT])
  );

  const showExlyConnect =
    !hiddenOfferings &&
    liveFeatures?.is_exlycon_active &&
    hasExlyConnectAccessPermission;

  const showTaskPercentContainer =
    open && isMainUser && orgPermissions.canCreate();

  const classes = useStyles({
    isXSmall,
    isDesktop,
    open,
    showTaskPercentContainer,
  });

  useEffect(() => {
    if (!open) setState(initialState);
  }, [open]);

  useEffect(() => {
    (async () => {
      try {
        // get whats next steps
        await getOnboardingContent();

        const steps = await dataProvider.custom_request(
          WHATS_NEXT_APIS.GET_PROGRESS,
          apiMethods.GET,
          { request_params: JSON.stringify([]) }
        );
        if (steps && isRequestSuccessful(steps.status)) {
          setNextStepsInLocalStorage({ data: steps.data });
          setTaskCompletedPercentage(getTasksCompletedPercentage());
          set_premium_data(steps);
          window.sessionStorage.setItem(
            SESSION_STORAGE_KEYS.HAS_LISTING,
            steps.has_listing
          );
        }

        const check_live_profileFeature = await getUserChecks();
        if (!is_empty(check_live_profileFeature)) {
          setLiveFeatures(check_live_profileFeature);
        }
      } catch (err) {
        console.log("menu [] err", err);
        /**
         * there are 2 apis being called in this try catch
         * TODO: after understanding the errors of these 2 apis
         * 1. mention against the condition for which api is that error handling condition valid for
         * 2. explicitly mention if the conditions left out arer intentional
         */
        if (!is_empty(err?.body?.message)) {
          notify(err.body.message, "error");
        }
      }
    })();
  }, []);

  useEffect(() => {
    startLoading();
    getPermissions({ forceCall: true })
      .then((menuPermissions) => {
        if (menuPermissions) {
          const permissions = { ...menuPermissions };
          set_permissions(permissions.hidden_items);
        }
      })
      .finally(stopLoading);
  }, []);

  const handleToggle = (menu) => {
    if (window.sessionStorage.getItem("fromRoute")) {
      window.sessionStorage.removeItem("fromRoute");
      window.sessionStorage.removeItem("edit");
    }
    const data = state;
    Object.keys(data).forEach((item) => {
      if (item !== menu) {
        data[item] = false;
      }
    });
    setState(data);
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  const handleMenuClick = () => {
    if (window.sessionStorage.getItem(SessionStorageConstants.FROM_ROUTE)) {
      window.sessionStorage.removeItem(SessionStorageConstants.FROM_ROUTE);
      window.sessionStorage.removeItem(SessionStorageConstants.EDIT);
    }

    // when navigating through menu, clear the entity data for offerings
    if (window.sessionStorage.getItem(SessionStorageConstants.ENTITY_DATA)) {
      window.sessionStorage.removeItem(SessionStorageConstants.ENTITY_DATA);
      window.sessionStorage.removeItem(SessionStorageConstants.PLAN_ID);
    }

    // when navigating through menu, clear the listing comm data
    if (window.sessionStorage.getItem(comms.STORAGE_KEY)) {
      window.sessionStorage.removeItem(comms.STORAGE_KEY);
    }

    onMenuClick();
  };
  const userData = JSON.parse(localStorage.getItem("auth"));
  let display_image,
    sub_domain = null;
  if (userData) {
    ({ display_image, sub_domain } = userData);
  }

  const has_permission = (section) => {
    if (is_empty(permissions)) return true;
    let result = true;
    for (const key in permissions) {
      if (
        section === "customer_tools" &&
        permissions[RESOURCE_KEYS.SECTIONS.MY_BOOKINGS] &&
        permissions["my_leads"] &&
        is_empty(permissions["my_leads"]) &&
        permissions["all_customers"]
      ) {
        result = false;
        break;
      }

      if (
        section === "workflow_tools" &&
        permissions["attendance_tracking"] &&
        permissions[RESOURCE_KEYS.SECTIONS.MY_SCHEDULE] &&
        permissions["certificates"] &&
        !isMainUser &&
        permissions["feedbacks"] &&
        !canAccessOrg &&
        (permissions[RESOURCE_KEYS.SECTIONS.REPORTS] ||
          !liveFeatures.is_premium) &&
        permissions["automated_message"] &&
        is_empty(permissions["automated_message"]) &&
        (permissions["blogs"] || !liveFeatures?.is_blogs_active) &&
        (permissions[RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY] ||
          !liveFeatures?.enable_media_library) &&
        !showExlyConnect &&
        (permissions[RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE] ||
          !liveFeatures?.is_zoom_attendance_enabled)
      ) {
        result = false;
        break;
      }

      if (
        section === "sales" &&
        permissions["payment_links"] &&
        is_empty(permissions["payment_links"]) &&
        permissions["discount_codes"] &&
        permissions["limited_time_offer"] &&
        permissions["custom_mails"] &&
        permissions["integrations"] &&
        permissions["traffic_analysis"] &&
        permissions[RESOURCE_KEYS.SECTIONS.WHATSAPP_BROADCASTS] &&
        permissions[RESOURCE_KEYS.SECTIONS.MEDIA_CREATIVES] &&
        permissions[RESOURCE_KEYS.SECTIONS.SEO] &&
        (!liveFeatures?.is_ads_enabled ||
          isDeviceIOS() ||
          !orgPermissions.isMainUser())
      ) {
        result = false;
        break;
      }
      if (
        section === "managing_money" &&
        permissions["manage_transactions"] &&
        is_empty(permissions["manage_transactions"])
      ) {
        result = false;
        break;
      }
      if (section === "org_tools" && permissions["org_logs"]) {
        result = false;
        break;
      }

      if (
        (key === section && is_empty(permissions[key])) ||
        (!is_empty(permissions[key]) && permissions[key]?.includes(section))
      ) {
        result = false;
        break;
      } else if (
        section === "webpage_details" &&
        key === "manage_webpage" &&
        is_empty(permissions["manage_webpage"])
      ) {
        result = false;
        break;
      } else if (
        section === "webpage_leads" &&
        key === "my_leads" &&
        is_empty(permissions["my_leads"])
      ) {
        result = false;
        break;
      }
    }
    return result;
  };

  const tutorialFlow = sessionStorage.getItem("flow");
  const share = getCreatorHostsiteURL();

  const showReferrals = useMemo(
    () =>
      liveFeatures?.is_creator_referral_active && // boolean to show referrals section or not
      has_permission(RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.REFER_A_FRIEND),
    [liveFeatures, has_permission]
  );
  const showUserReferrals =
    liveFeatures?.user_referral_enabled === true && // boolean to show user referrals section or not
    has_permission(RESOURCE_KEYS.SUBSECTIONS.EXLY_PLUS.USER_REFERRALS);
  const showExlyPlusSection =
    !isLoading &&
    has_permission(RESOURCE_KEYS.SECTIONS.EXLY_PLUS) &&
    (showReferrals || showUserReferrals);

  if (is_empty(premium_data)) {
    return <></>;
  }

  const commonMenuItemLinkProps = {
    onClick: handleMenuClick,
    sidebarIsOpen: open,
    dense,
    disableRipple: true,
    classes: { root: classes.innerMenuLink },
    activeClassName: classes.activeMenuItem,
  };

  const commonCustomMenuItemLinkProps = {
    onClick: () => {
      handleMenuClick();
      setState(initialState);
    },
    sidebarIsOpen: open,
    dense,
    disableRipple: true,
    classes: { root: classes.menuLink },
    activeClassName: classes.activeMenuLink,
  };

  const MANAGE_WEBPAGE = has_permission("webpage_tools") && (
    <>
      <div id="manage_webpage">
        <ParentMenu
          handleToggle={() => handleToggle("menuManageWebPage")}
          isOpen={state.menuManageWebPage}
          sidebarIsOpen={open}
          name="Manage Webpage"
          icon={<ManageWebpageIcon />}
          dense={dense}
        >
          {has_permission("webpage_details") && (
            <MenuItemLink
              id={"webpage_details"}
              to={`/TemplatePreview`}
              primaryText={`Create / Edit Webpage`}
              {...commonMenuItemLinkProps}
            />
          )}
          <MenuItemLink
            id={"faq"}
            to={`/${api.faq_list}`}
            primaryText={`FAQs`}
            {...commonMenuItemLinkProps}
          />
        </ParentMenu>
      </div>
    </>
  );

  const CUSTOMERS = has_permission("customer_tools") && (
    <div id="Customer_Tools">
      <ParentMenu
        handleToggle={() => handleToggle("menuCustomers")}
        isOpen={state.menuCustomers}
        sidebarIsOpen={open}
        name="Contacts"
        icon={<CustomersIcon />}
        dense={dense}
        initialState={{ my_bookings: false, my_leads: false }}
      >
        {has_permission(RESOURCE_KEYS.SECTIONS.MY_BOOKINGS) && (
          <MenuItemLink
            to={`/my-bookings`}
            primaryText={`My Bookings`}
            id={"bookings"}
            {...commonMenuItemLinkProps}
          />
        )}
        {liveFeatures.enable_leadgen && has_permission("my_leads") ? (
          <SubMenu name="Leads" dense={dense} id="my_leads">
            {has_permission("webpage_leads") && (
              <MenuItemLink
                id="webpage_leads"
                to={`/host/v2/interest/list`}
                primaryText={`Webpage Leads`}
                {...commonMenuItemLinkProps}
              />
            )}
            {has_permission("ad_leads") && (
              <MenuItemLink
                id="ad_leads"
                to={`/${api.ad_leads}`}
                primaryText={`Ad Leads`}
                {...commonMenuItemLinkProps}
              />
            )}
          </SubMenu>
        ) : (
          has_permission("webpage_leads") && (
            <MenuItemLink
              id="webpage_leads"
              to={`/host/v2/interest/list`}
              primaryText={`Webpage Leads`}
              {...commonMenuItemLinkProps}
            />
          )
        )}
        {has_permission("all_customers") && (
          <MenuItemLink
            to={`/payments/exly/customers/list/v2`}
            id={"all_customers"}
            primaryText={`All Customers`}
            {...commonMenuItemLinkProps}
          />
        )}

        {has_permission("segmentation") && (
          <MenuItemLink
            to={`/host/segments/list/customers`}
            id={"all_segments"}
            primaryText={`Segments`}
            {...commonMenuItemLinkProps}
          />
        )}
      </ParentMenu>
    </div>
  );

  const getReadyToLaunchText = `Get ready to launch (${taskCompletedPercentage}%)`;

  const showReports =
    liveFeatures.is_premium && has_permission(RESOURCE_KEYS.SECTIONS.REPORTS);

  return (
    <Box
      mt={1}
      className={`${classes.sidebarIsFixed} ${
        tutorialFlow ? "disableLeftClick" : ""
      }`}
    >
      {isDesktop ? null : (
        <div className={classes.headerContainer}>
          <div>Exly</div>
          <IconButton
            onClick={() => dispatch(setSidebarVisibility(false))}
            className={classes.headerCloseBtn}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
      )}

      {showTaskPercentContainer && (
        <div className={classes.taskPercentContainer}>
          <div
            onClick={() => {
              window.location.href = `${window.location.origin}/#/whats-next`;
              !is_desktop && dispatch(setSidebarVisibility(false));
            }}
            className={`p-3 border-bottom position-relative ${
              whatsNextStyles.sidebarLink
            } ${
              window.location.href === whatsNextUrl
                ? whatsNextStyles.sidebarLinkActive
                : ""
            } `}
          >
            <span style={{ fontSize: "14px" }} className="font-weight-bold">
              <img
                alt={getReadyToLaunchText}
                src={require("../../../assets/images/lightbulb-24px.svg")}
              />{" "}
              {getReadyToLaunchText}
            </span>

            <div className="progress mt-2" style={{ height: "6px" }}>
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: `${taskCompletedPercentage}%` }}
                aria-valuenow={taskCompletedPercentage}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
        </div>
      )}

      <div className={classes.scrollContainer}>
        {isDesktop && has_permission("dashboard") && (
          <CustomMenuItemLink
            id={"dashboard"}
            to={`/Dashboard`}
            primaryText={`Dashboard`}
            leftIcon={<DashboardIcon />}
            {...commonCustomMenuItemLinkProps}
          />
        )}

        {isDesktop && has_permission("create_edit_listing") && (
          <CustomMenuItemLink
            to={
              getWhatsNextInfo()?.has_listing ||
              !orgPermissions.canCreateOffering()
                ? LISTINGS_ROUTE_IDS[LISTINGS_ROUTE_KEYS.LISTINGS_LIST]
                : LISTINGS_ROUTE_IDS[LISTINGS_ROUTE_KEYS.CREATE_LISTING]
            }
            id={"create_edit_listing"}
            primaryText={`${
              orgPermissions.canCreateOffering()
                ? "Create / Edit Offerings"
                : "My Offerings"
            }`}
            leftIcon={<ListingsIcon />}
            {...commonCustomMenuItemLinkProps}
          />
        )}
        {isDesktop && has_permission("sales") && (
          <CustomMenuItemLink
            id={"sales"}
            to={`/sales-and-marketing`}
            primaryText={`Sales & Marketing`}
            leftIcon={<SalesIcon />}
            {...commonCustomMenuItemLinkProps}
          />
        )}
        {isDesktop &&
          has_permission("managing_money") &&
          has_permission("manage_transactions") && (
            <CustomMenuItemLink
              id="all_transactions"
              to={
                has_permission("all_transaction")
                  ? `/${api.transaction_list}`
                  : "/payments/exly/subscription/list"
              }
              primaryText={`Transactions`}
              leftIcon={<TransactionIcon />}
              {...commonCustomMenuItemLinkProps}
            />
          )}

        {isDesktop ? (
          <>
            {CUSTOMERS}
            {MANAGE_WEBPAGE}
          </>
        ) : (
          <>
            {MANAGE_WEBPAGE}
            {CUSTOMERS}
          </>
        )}

        {has_permission("workflow_tools") && (
          <div id="Workflow_Tools">
            <ParentMenu
              handleToggle={() => handleToggle("menuWorkflowTools")}
              isOpen={state.menuWorkflowTools}
              sidebarIsOpen={open}
              name="Workflow tools"
              icon={<StarIcon />}
              dense={dense}
            >
              {has_permission(RESOURCE_KEYS.SECTIONS.ZOOM_ATTENDANCE) &&
                liveFeatures?.is_zoom_attendance_enabled && (
                  <MenuItemLink
                    {...getUniqueMenuItemLinkProps({
                      routeKey: app_route_keys.zoom_attendance,
                    })}
                    {...commonMenuItemLinkProps}
                  />
                )}
              {has_permission(RESOURCE_KEYS.SECTIONS.MY_SCHEDULE) && (
                <MenuItemLink
                  to={`/myschedule`}
                  id={"myschedule"}
                  primaryText={`My Schedule`}
                  {...commonMenuItemLinkProps}
                />
              )}
              {has_permission("attendance_tracking") &&
                liveFeatures.is_attendance_enabled && (
                  <MenuItemLink
                    to={`/attendance-tracking`}
                    id={"attendance-register"}
                    primaryText={`Attendance Register`}
                    {...commonMenuItemLinkProps}
                  />
                )}
              {(has_permission(RESOURCE_KEYS.SECTIONS.CERTIFICATES) ||
                isMainUser) && (
                <MenuItemLink
                  to={`/create-certificate`}
                  id={"certificates"}
                  primaryText={`Certificates`}
                  {...commonMenuItemLinkProps}
                />
              )}

              {has_permission("feedbacks") && (
                <MenuItemLink
                  to={`/${api.feedbacks.fetch_feedbacks_list}`}
                  id={"exly_feedbacks"}
                  primaryText={`Feedback`}
                  {...commonMenuItemLinkProps}
                />
              )}
              {showExlyConnect ? (
                <MenuItemLink
                  to={`/${app_pathnames[app_route_keys.exly_connect](
                    EXLY_CONNECT_TABS.scheduled.value
                  )}`}
                  id="exly-connect"
                  primaryText="Exly Connect"
                  onClick={handleMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
                  disableRipple={true}
                  classes={{ root: classes.innerMenuLink }}
                  activeClassName={classes.activeMenuItem}
                />
              ) : null}
              {/* TODO: org v3 will be removed after testing success of org -v3 */}
              {/* {has_permission("org_tools") &&
                <MenuItemLink
                id="org_logs"
                to={`/organisation`}
                primaryText={`Add/Manage Staff`}
                {...commonMenuItemLinkProps}
              />
                )} */}

              {canAccessOrg && (
                <MenuItemLink
                  id="org_logs"
                  to={`/${api.get_organisation_members}`}
                  primaryText={`Add/Manage Staff`}
                  {...commonMenuItemLinkProps}
                />
              )}

              {showReports && (
                <MenuItemLink
                  to={`/Reports`}
                  id="reports"
                  primaryText={`Reports`}
                  {...commonMenuItemLinkProps}
                />
              )}
              {has_permission("automated_message") && (
                <MenuItemLink
                  to={`/notifications/automated-messages`}
                  id={"automated_message"}
                  primaryText={`Automated Messages`}
                  {...commonMenuItemLinkProps}
                />
              )}
              {has_permission("blogs") && liveFeatures?.is_blogs_active && (
                <MenuItemLink
                  to={`/Blogs`}
                  id={"blogs"}
                  primaryText={`Blog`}
                  {...commonMenuItemLinkProps}
                />
              )}
              {liveFeatures?.enable_media_library &&
                has_permission(RESOURCE_KEYS.SECTIONS.MEDIA_LIBRARY) && (
                  <MenuItemLink
                    to={`/media-library`}
                    id={"exly_media_library"}
                    primaryText={`Media Library`}
                    {...commonMenuItemLinkProps}
                  />
                )}
            </ParentMenu>
          </div>
        )}

        {has_permission(RESOURCE_KEYS.SECTIONS.INTEGRATIONS) && (
          <CustomMenuItemLink
            id={app_route_keys.integrations}
            to={app_route_ids[app_route_keys.integrations]}
            primaryText={app_route_titles[app_route_keys.integrations]}
            leftIcon={<IntegrationsIcon />}
            {...commonCustomMenuItemLinkProps}
            classes={{
              root: classnames(classes.menuLink, classes.soloMenuItem),
            }}
          />
        )}

        {showExlyPlusSection && (
          <ParentMenu
            handleToggle={() => handleToggle("menuExlyPlus")}
            isOpen={state.menuExlyPlus}
            sidebarIsOpen={open}
            name="Exly Plus"
            icon={<ExlyPlusIcon />}
            dense={dense}
          >
            {showReferrals && (
              <MenuItemLink
                to={`/Referrals`}
                id="referrals"
                primaryText={`Refer a Friend`}
                {...commonMenuItemLinkProps}
              />
            )}
            {showUserReferrals && (
              <MenuItemLink
                to={`/payments/exly/user/referrals/list`}
                id="user_referrals"
                primaryText={`User Referrals`}
                {...commonMenuItemLinkProps}
              />
            )}
          </ParentMenu>
        )}
      </div>

      {open && (
        <div
          className={classes.helpContainer}
          onClick={openExlySupportWhatsAppUrl}
        >
          <TalkToExpertIcon />
          <span>Talk to our Expert</span>
          <DoubleArrowIcon />
        </div>
      )}

      {display_image && open ? (
        <>
          {sub_domain ? (
            <div className={classes.webpageAddress}>
              <div>Your Webpage Address:</div>
              <div className={classes.linkWrapper}>
                <Link
                  target={"_blank"}
                  className={classnames(
                    classes.max1Line,
                    classes.subdomainLink
                  )}
                  rel={"noopener noreferrer"}
                  href={share.url}
                >
                  {share.previewUrl}
                </Link>
                <div
                  className={classes.copyLink}
                  onClick={() => {
                    if (navigator && navigator.clipboard) {
                      navigator.clipboard.writeText(share.url);
                      notify("Link Copied!", "info");
                    }
                  }}
                >
                  <CopyIcon />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Menu;
