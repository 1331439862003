// @ts-nocheck
import React from "react";
import { MenuItemLink, MenuProps } from "react-admin";
import { Tooltip } from "@my-scoot/component-library-legacy";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";

const CustomMenuItemLink = (props: MenuProps) => {
  const { primaryText, ...restProps } = props;
  return (
    <Tooltip title={primaryText} placement="right" color="primary" arrow>
      <MenuItemLink
        primaryText={props.sidebarIsOpen ? primaryText : ""}
        {...restProps}
      />
    </Tooltip>
  );
};

export default withComponentLibraryTheme(CustomMenuItemLink);
