import { makeStyles } from "@material-ui/core/styles";

interface IStyle {
  isActive?: boolean;
  isDesktop?: boolean;
  sidebarIsOpen?: boolean;
}

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: ({ isActive }: IStyle) => isActive ? "#493AB1": "#6D675F",
    display: "flex",
    alignItems: "center",
    justifyContent: ({ sidebarIsOpen }: IStyle) =>
      sidebarIsOpen ? "flex-start" : "center",
    gap: "12px",
    lineHeight: "20px",
    padding: ({ isDesktop, sidebarIsOpen }: IStyle) => {
      if (!sidebarIsOpen) return "12px 0 13px 0";
      return isDesktop ? "12px 8px 13px 24px" : "12px 17px 13px 17px";
    },
    "&:hover": {
      background: "#fff",
    },
  },
  menuItemContent: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    lineHeight: "20px",
    fontSize: "16px",
    fontWeight: 500,
  },
  icon: {
    minWidth: 0,
    height: "20px",
    "& svg": {
      filter: ({ isActive }: IStyle) =>
      isActive &&
        "invert(23%) sepia(35%) saturate(4522%) hue-rotate(238deg) brightness(86%) contrast(91%)",
    },
  },
  wrapperInner: {
    padding: ({ isDesktop }: IStyle) =>
      isDesktop ? "14px 0 13px 36px" : "12.75px 0 13px 26.75px",
  },

  collapseList: {
    borderLeft: "2px solid #CFCDC9",
    display: "flex",
    flexDirection: "column",
    paddingLeft: ({ isDesktop }: IStyle) => (isDesktop ? "27px" : "21.25px"),
    gap: ({ isDesktop }: IStyle) => (isDesktop ? "19px" : "19px"),
  },
}));

export default useStyles;
